<template>
  <div class="events mb-6">
    <div class="events_local">
      <vue-position-sticky>
        <div class="fix bg-white">
          <h3 class="events_local_title">
            <span>{{ title || $t('locais.eventos.title') }}</span>
          </h3>
          <div>
            <p class="subtitle m-0">{{ $t('locais.eventos.subtitle') }}</p>
            <p class="text-xs m-0">{{ dia }}</p>
            <p class="text-2xl font-bold m-0">{{ hora }}</p>
          </div>
        </div>
      </vue-position-sticky>
      <p class="info m-0 text-gray-800 bg-roxo-claro p-1 rounded font-bold">
        {{ $t('locais.eventos.info') }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['title'],
  data: () => ({
    dia: '',
    hora: ''
  }),
  methods: {
    setDateTime() {
      setInterval(() => {
        let dataAtual = moment()
        this.dia = dataAtual.format('ddd, DD MMMM YYYY')
        this.hora = dataAtual.format('HH:mm:ss')
      }, 1000)
    }
  },
  mounted() {
    moment.locale(this.$i18n.locale)
    this.setDateTime()
  }
}
</script>

<style lang="scss" scoped>
.events {

  .events_local {
    .events_local_title {
      margin: 5px 0;
    }



    .info {
      margin: 0 auto;
      width: 90%;
      font-size: 11px;
      border: 1px solid #673bb7;
    }
  }
}

.fix {
  padding: 5px 0;
}
</style>
